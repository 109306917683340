//============================================
// Formular
//============================================

const step2 = (e) => { 
  const step1 = document.querySelector('.step--1')
  const step2 = document.querySelector('.step--2')
  const step1Content = document.querySelector('.step-1-content')
  const step2Content = document.querySelector('.step-2-content')
  step1.style.display = "none"
  step2.style.display = "block"
  step2Content.style.display = "block"
  step1Content.style.display = "none"
}

if (document.getElementById("form")) {
    const form = document.getElementById("form");
    const btnPlz = form.querySelector('[data-name="plz"]');
    const btnTel = form.querySelector('[data-name="tel"]');
    const btnName = form.querySelector('[data-name="name"]');
    const submitBtn = form.querySelector("#submit-btn");
   

  //Inputs
  const plz = document.getElementById('plz')
  const tel = document.getElementById('tel')
  const vorname = document.getElementById('vorname')
  const nachname = document.getElementById('name')
  const mail = document.getElementById('mail')


    const isValidString = (el, err) => {
      const input = document.getElementById(el).value;    
      const errorMsg = document.getElementById(err)
        if (/\d/.test(input) === true || input.length < 2) {
          errorMsg.innerHTML = '<div class="error">Bitte einen gültigen Namen angeben!</div>'
        } else { 
        return true
        }    
    };
    
    /* =============================
     * Telefonvalidierung
    ============================= */
    
    const checkTelInput = (el, err) => {
        const tel = document.getElementById(el).value;
      const input = document.getElementById(el)
      const errorMsg = document.getElementById(err)
        const check = /^[0-9()+/– -]+$/;
        if (check.test(tel) && tel.replace(/[^0-9]/g,"").length > 3) {
        return true
        } else { 
        errorMsg.innerHTML = '<div class="error">Bitte gültige Telefonnummer angeben</div>'
        }
    };

 

    /* =============================
     * Plz validierung
    ============================= */
    const checkPlzInput = (el, err) => {
        const plz = document.getElementById(el).value;
      const input = document.getElementById(el)
      const errorMsg = document.getElementById(err)
        if (plz.length === 5) {
         return true
        } else { 
          errorMsg.innerHTML = '<div class="error">Bitte korrekte Postleitzahl eingeben!</div>'
        }
    };

    /* =============================
     * E-Mail Validierung
    ============================= */

    const checkEmailInput = (el, err) => {
        const email = document.getElementById(el).value;
        const errorMsg = document.getElementById(err)
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(email)) {
        return true
        } else { 
          errorMsg.innerHTML = '<div class="error">Bitte korrekte E-Mail Adresse eingeben!</div>'
        }
    };


  const isCheckboxChecked = (el, error, msg) => { 
    const checkbox = document.getElementById(el)
    const err = document.getElementById(error);
    if (checkbox.checked) {
      err.classList.remove("alert");
      err.innerHTML = "";
      return true
    } else { 
      err.classList.add("alert");
      err.innerHTML = msg;
      return false
    }
  }

  function getValueFromRadioButton(name) {
    //Get all elements with the name
    var buttons = document.getElementsByName(name);
    for (var i = 0; i < buttons.length; i++) {
      //Check if button is checked
      var button = buttons[i];
      if (button.checked) {
        //Return value
        return button.value;
      }
    }
    //No radio button is selected.
    return null;
  }
  form.addEventListener('submit', (e) => {
    e.preventDefault()
    const hp = document.getElementById('hp').value
    
    if (hp.length > 0) {
      console.log('ERROR');
      return
    }   
    else {form.submit()}
  })

  submitBtn.addEventListener('click', e => { 
    e.preventDefault()
    const consent = document.getElementById('check')
    const alertConsent = document.getElementById('alertConsent')
    form.reportValidity()
    if (!consent.checked) {
      alertConsent.innerHTML = "<div class='error'>Bitte akzeptieren Sie die Datenschutzbedingungen</div>"
    } else {
      if (
        checkPlzInput('plz', 'alertPlz') &&
        checkTelInput('tel', 'alertTel') &&
        isValidString('name', 'alertName') &&
        checkEmailInput('mail', 'alertEmail')
          ) { 
        form.submit()
      }
    }

  })  

}

const toggleForm = () => { 
  const formularBereich = document.getElementById('formularbereich')
  formularBereich.classList.toggle('toggled')
}
  
const openForm = () => { 
  const formularBereich = document.getElementById('formularbereich')
  formularBereich.classList.remove('toggled')
}
  
if (document.querySelector('#formularbereich:not(.direct)')) { 
  window.onload = function () { 
    const formularBereich = document.getElementById('formularbereich')
    setTimeout(function () { 
      formularBereich.classList.remove('toggled')
    }, 3000)
  }
}
if (document.querySelector('#formularbereich.direct')) { 
  window.onload = function () { 
    const formularBereich = document.getElementById('formularbereich')    
      formularBereich.classList.remove('toggled')
  }
}

